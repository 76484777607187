import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import React from 'react';

class ImageLB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    render() {
        return(
            <div className='portfolio-item'>
                <div className='hover-bg'>
                    {' '}
                    <a
                    href="#portfolio"
                    onClick={() => {this.setState({isOpen: true})}}
                    title={this.props.title}
                    data-lightbox-gallery='gallery1'
                    >
                    <div className='hover-text'>
                        <h4>{this.props.title}</h4>
                    </div>
                    { this.state.isOpen && (<Lightbox reactModalStyle={{zIndex:20000000}} mainSrc={this.props.largeImage} onClick={() => this.setState({isOpen:false})} onCloseRequest={() => this.setState({isOpen:false})}></Lightbox>)}
                    <img
                        src={this.props.smallImage}
                        className='img-responsive'
                        alt={this.props.title}
                    />
                    
                    {' '}
                    </a>{' '}
                </div>
            </div>
        )
    }
}

export default ImageLB